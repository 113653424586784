var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left p-20"},[_c('div',{staticClass:"mb-40"},[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelCol":{ span: 7 },"wrapperCol":{ span: 11 },"labelAlign":"left","colon":false}},[_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("内容详情")])],1),_c('div',[_c('gf-form-item',{attrs:{"prop":"Title","label":"标题","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 30,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              } ]}},[_c('gf-re-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model.Title),callback:function ($$v) {_vm.$set(_vm.model, "Title", $$v)},expression:"model.Title"}})],1),_c('gf-form-item',{attrs:{"prop":"TypeId","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"类型","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled},model:{value:(_vm.model.TypeId),callback:function ($$v) {_vm.$set(_vm.model, "TypeId", $$v)},expression:"model.TypeId"}},_vm._l((_vm.options.PolicyType.filter(function (item) { return [2, 5].includes(item.ID); }
                )),function(option){return _c('a-select-option',{key:option.ID},[_vm._v(" "+_vm._s(option.Name)+" ")])}),1)],1),_c('gf-form-item',{attrs:{"prop":"Source","rules":{
              required: true,
              message: '请选择',
              trigger: ['change', 'blur'],
            },"label":"来源","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"placeholder":"请输入","disabled":_vm.disabled},model:{value:(_vm.model.Source),callback:function ($$v) {_vm.$set(_vm.model, "Source", $$v)},expression:"model.Source"}})],1),_c('gf-form-item',{attrs:{"prop":"PublicTime","rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"发布时间"}},[_c('a-date-picker',{attrs:{"placeholder":"请选择年/月/日","valueFormat":"x","size":"large"},model:{value:(_vm.model.PublicTime),callback:function ($$v) {_vm.$set(_vm.model, "PublicTime", $$v)},expression:"model.PublicTime"}})],1),_c('gf-form-item',{attrs:{"prop":"Target","required":true,"label":"受众群体","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{attrs:{"placeholder":"请输入","disabled":_vm.disabled},model:{value:(_vm.model.Target),callback:function ($$v) {_vm.$set(_vm.model, "Target", $$v)},expression:"model.Target"}})],1),_c('gf-form-item',{attrs:{"prop":"RewardsFlag","rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },"label":"反馈信息采纳是否支付费用","wrapperCol":{ span: 7 }}},[_c('a-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled},model:{value:(_vm.model.RewardsFlag),callback:function ($$v) {_vm.$set(_vm.model, "RewardsFlag", $$v)},expression:"model.RewardsFlag"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 是 ")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 否 ")])],1)],1),(_vm.model.RewardsFlag)?_c('gf-form-item',{attrs:{"prop":"Rewards","rules":{
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },"label":"将选取多少个信息反馈者予以奖励","wrapperCol":{ span: 7 }}},[_c('a-input-number',{attrs:{"min":1},model:{value:(_vm.model.Rewards),callback:function ($$v) {_vm.$set(_vm.model, "Rewards", $$v)},expression:"model.Rewards"}})],1):_vm._e(),_c('gf-form-item',{attrs:{"prop":"regionId","wrapperCol":{ span: 7 },"rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"地区"}},[_c('a-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled},model:{value:(_vm.model.regionId),callback:function ($$v) {_vm.$set(_vm.model, "regionId", $$v)},expression:"model.regionId"}},_vm._l((_vm.options.PolicyRegion),function(option){return _c('a-select-option',{key:option.ID},[_vm._v(" "+_vm._s(option.Name)+" ")])}),1)],1),_c('gf-form-item',{attrs:{"prop":"Attachments","label":"上传附件"}},[_c('file-upload',{attrs:{"size":20,"accept":".pdf,.docx,.doc,.png,.jpg,.jpeg,.xls,.xlsx"},model:{value:(_vm.model.Attachments),callback:function ($$v) {_vm.$set(_vm.model, "Attachments", $$v)},expression:"model.Attachments"}})],1),_c('gf-form-item',{attrs:{"prop":"Desc","wrapperCol":{ span: 16 },"rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ],"label":"正文"}},[_c('gf-editor',{model:{value:(_vm.model.Desc),callback:function ($$v) {_vm.$set(_vm.model, "Desc", $$v)},expression:"model.Desc"}})],1)],1)])])],1),_c('div',{staticClass:"text-center"},[_vm._t("default",null,null,{ validate: _vm.validate })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }