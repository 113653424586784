<template>
  <div class="mb-40 p-20 bg-white">
    <a-form-model
      ref="container"
      :model="model"
      :labelCol="{ span: 5 }"
      :wrapperCol="{ span: 11 }"
      labelAlign="left"
      :colon="false"
    >
      <div class="mb-40">
        <div>
          <gf-form-item
            prop="Title"
            label="标题"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <gf-re-input
              :disabled="disabled"
              v-model="model.Title"
            ></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="TypeId"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="类型"
            :wrapperCol="{ span: 7 }"
          >
            <a-select
              placeholder="请选择"
              :disabled="disabled"
              v-model="model.TypeId"
            >
              <a-select-option
                v-for="option in options.PolicyType.filter((item) =>
                  [1, 3, 4].includes(item.ID)
                )"
                :key="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </gf-form-item>
          <gf-form-item
            prop="Source"
            :rules="{
              required: true,
              message: '请选择',
              trigger: ['change', 'blur'],
            }"
            label="来源"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input
              placeholder="请输入"
              :disabled="disabled"
              v-model="model.Source"
            ></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="PublicTime"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="发布时间"
          >
            <a-date-picker
              placeholder="请选择年/月/日"
              valueFormat="x"
              size="large"
              v-model="model.PublicTime"
            ></a-date-picker>
          </gf-form-item>

          <gf-form-item
            prop="regionId"
            :wrapperCol="{ span: 7 }"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="地区"
          >
            <a-select
              placeholder="请选择"
              :disabled="disabled"
              v-model="model.regionId"
            >
              <a-select-option
                v-for="option in options.PolicyRegion"
                :key="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </gf-form-item>
          <gf-form-item
            prop="Attachments"
            label="上传附件"
          >
            <file-upload
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg,.xls,.xlsx"
              v-model="model.Attachments"
            ></file-upload>
          </gf-form-item>
          <gf-form-item
            prop="Desc"
            :wrapperCol="{ span: 18 }"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="正文"
          >
            <!-- <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="500"
                v-model="model.Desc"
              ></gf-re-textarea> -->
            <gf-editor v-model="model.Desc"></gf-editor>
          </gf-form-item>
        </div>
      </div>
    </a-form-model>
    <div class="text-center">
      <a-space :size="20">
        <a-button size="large" type="primary" ghost @click="back"
          >取消</a-button
        >
        <a-button size="large" type="primary" @click="submit"> 提交 </a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import policyForm from "../../policy/components/policyForm.vue";
import { createPolicy } from "@/api/api/policy";
export default {
  components: { policyForm },
  data() {
    return {
      model: {},
      disabled: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    restore(data) {
      const temp = { ...data };
      temp.Type = this.options.PolicyType[temp.TypeId - 1];
      temp.Region = this.options.PolicyRegion[temp.regionId - 1];
      temp.PublicTime = new Date(+temp.PublicTime).toISOString();
      return temp;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve(this.restore(this.model));
          } else {
            reject();
          }
        });
      });
    },
    back() {
      this.$router.back();
    },
    submit() {
      this.validate().then((res) => {
        createPolicy(res)
          .then(() => {
            this.$message.success("创建政策成功");
            this.back();
          })
          .catch(() => {
            this.$message.error("创建政策失败");
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>