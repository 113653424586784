<template>
  <div class="text-left p-20">
    <div class="mb-40">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 7 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>内容详情</gf-title>
          </div>
          <div>
            <gf-form-item
              prop="Title"
              label="标题"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
                {
                  max: 30,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <gf-re-input
                :disabled="disabled"
                v-model="model.Title"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="TypeId"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="类型"
              :wrapperCol="{ span: 7 }"
            >
              <a-select
                placeholder="请选择"
                :disabled="disabled"
                v-model="model.TypeId"
              >
                <a-select-option
                  v-for="option in options.PolicyType.filter((item) =>
                    [2, 5].includes(item.ID)
                  )"
                  :key="option.ID"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              prop="Source"
              :rules="{
                required: true,
                message: '请选择',
                trigger: ['change', 'blur'],
              }"
              label="来源"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                placeholder="请输入"
                :disabled="disabled"
                v-model="model.Source"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="PublicTime"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="发布时间"
            >
              <a-date-picker
                placeholder="请选择年/月/日"
                valueFormat="x"
                size="large"
                v-model="model.PublicTime"
              ></a-date-picker>
            </gf-form-item>
            <gf-form-item
              prop="Target"
              :required="true"
              label="受众群体"
              :wrapperCol="{ span: 7 }"
            >
              <gf-re-input
                placeholder="请输入"
                :disabled="disabled"
                v-model="model.Target"
              ></gf-re-input>
            </gf-form-item>
            <gf-form-item
              prop="RewardsFlag"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
              label="反馈信息采纳是否支付费用"
              :wrapperCol="{ span: 7 }"
            >
              <a-select
                placeholder="请选择"
                :disabled="disabled"
                v-model="model.RewardsFlag"
              >
                <a-select-option :value="1"> 是 </a-select-option>
                <a-select-option :value="0"> 否 </a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item
              v-if="model.RewardsFlag"
              prop="Rewards"
              :rules="{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              }"
              label="将选取多少个信息反馈者予以奖励"
              :wrapperCol="{ span: 7 }"
            >
              <a-input-number :min="1" v-model="model.Rewards"></a-input-number>
            </gf-form-item>
            <gf-form-item
              prop="regionId"
              :wrapperCol="{ span: 7 }"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="地区"
            >
              <a-select
                placeholder="请选择"
                :disabled="disabled"
                v-model="model.regionId"
              >
                <a-select-option
                  v-for="option in options.PolicyRegion"
                  :key="option.ID"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </gf-form-item>
            <gf-form-item prop="Attachments" label="上传附件">
              <file-upload
                :size="20"
                accept=".pdf,.docx,.doc,.png,.jpg,.jpeg,.xls,.xlsx"
                v-model="model.Attachments"
              ></file-upload>
            </gf-form-item>
            <gf-form-item
              prop="Desc"
              :wrapperCol="{ span: 16 }"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
              label="正文"
            >
              <!-- <gf-re-textarea
                :disabled="disabled"
                :rows="5"
                :max="500"
                v-model="model.Desc"
              ></gf-re-textarea> -->
              <gf-editor v-model="model.Desc"></gf-editor>
            </gf-form-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
  },
  created() {
    const temp = Object.assign(this.model, { ...this.dataSource });
    temp.RewardsFlag = Number(Boolean(temp.Rewards));
    temp.PublicTime = moment(temp.PublicTime);
    this.model = { ...temp };
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    restore(data) {
      const temp = { ...data };
      temp.Type = this.options.PolicyType[temp.TypeId - 1];
      temp.Region = this.options.PolicyRegion[temp.regionId - 1];
      temp.PublicTime = new Date(+temp.PublicTime).toISOString();
      return temp;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve(this.restore(this.model));
          } else {
            reject();
          }
        });
      });
    },
    validateSceneCount(...rest) {
      return validateSceneCount(...rest);
    },
  },
  watch: {
    dataSource(val) {
      const temp = Object.assign(this.model, val);
      temp.RewardsFlag = Number(Boolean(temp.Rewards));
      temp.PublicTime = moment(temp.PublicTime);
      this.model = { ...temp };
    },
  },
};
</script>